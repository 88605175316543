import React, { useState, useEffect } from "react";

import WhiteHeader from "../Stepes/whiteHeader";
// import loadable from '@loadable/component';

const Layout = ({ children }) => {
  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", listenToScroll);
  }, []);

  const listenToScroll = () => {
    setScrolled(true);
  };
  return (
    <div className="min-h-screen flex flex-col justify-between">
      <WhiteHeader blog />
      {children}

      {/* <WhiteFooter /> */}
      <div className="w-full bg-black py-8 text-center">
        <p>
          © Copyright 2023&nbsp;
          <a
            href="https://www.tomedes.com/"
            target="_blank"
            className="hover:text-yellow"
          >
            Tomedes
          </a>
          &nbsp;All Rights Reserved.
        </p>
      </div>
    </div>
  );
};

export default Layout;
