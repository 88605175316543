import React from "react";

function HomePage() {
  return (
    <div className="text-black">
      <div className="max-w-6xl mx-auto p-4">
        <h1 className="text-4xl">Welcome to AI Post Editing!</h1>
        <p>
          Your Ultimate Solution for AI-powered Services, Enhanced by Human
          Expertise
        </p>
        <p>
          At AI Post Editing, we believe in harnessing the power of artificial
          intelligence and combining it with the precision of human expertise to
          deliver top-notch, reliable services to our clients. We offer a wide
          range of solutions tailored to meet the diverse needs of businesses
          and individuals alike.
        </p>
        <h3 className="text-2xl">Our Offerings:</h3>
        <p>
          Machine Translation Post Editing – Receive fast, accurate translations
          powered by AI, with our professional linguists refining and perfecting
          the output for flawless results.
        </p>
        <p>
          AI-generated Content Review – Get exceptional content crafted by AI,
          and let our experienced editors polish it for clarity, coherence, and
          maximum impact.
        </p>
        <p>
          Legal Agreement Writing – Benefit from AI-assisted drafting of legal
          agreements, followed by our expert legal editors reviewing and
          refining the documents to ensure accuracy and compliance.
        </p>
        <p>
          Image Editing & Enhancement – Our skilled graphic designers work
          alongside AI tools to optimize and enhance your images, producing
          stunning visuals that captivate your audience.
        </p>
        <p>
          Data Validation & Quality Assurance – Ensure the accuracy of
          AI-generated data with our team of experts who meticulously verify and
          validate your results.
        </p>
        <h3 className="text-2xl">Why Choose AI Post Editing?</h3>
        <p>
          Seamless Integration: Our unique approach blends AI capabilities with
          human expertise to provide you with the best of both worlds.
        </p>
        <p>
          Fast Turnaround Time: Experience rapid results without sacrificing
          quality, thanks to our efficient and streamlined process.
        </p>
        <p>
          Scalability: Whether you need assistance with a single project or
          ongoing support, we can easily adapt to your needs.
        </p>
        <p>
          Customized Solutions: We understand that every client has unique
          requirements, and we tailor our services to meet those specific needs.
        </p>
        <p>
          Security & Confidentiality: We prioritize the privacy and security of
          your data, ensuring it remains protected at all times.
        </p>
      </div>
    </div>
  );
}

export default HomePage;
