import { Link } from "gatsby";
import React from "react";

const WhiteHeader = ({ blog }) => {
  return (
    <div className="bg-white py-10 mb-[-1px]">
      <div className="2xl:w-[70%] sm:w-[87%] mx-auto sm:px-0 px-5 flex justify-between">
        <Link to="/" className="text-black text-[20px]">
          <span className="font-opensans font-bold text-[#3f1573]">AI</span>
          Postediting.com
        </Link>
      </div>
    </div>
  );
};

export default WhiteHeader;
