import React from "react";
import Seo from "../components/seo";
import Layout from "../components/Layout/index";
import Translate from "../components/Translate/Translate";
import HomePage from "../components/HomePage";

const index = () => {
  return (
    <Layout>
      <Seo />
      <HomePage />
      {/* <Translate /> */}
    </Layout>
  );
};

export default index;
